import { makeStyles } from '@material-ui/core';
import { CUT_OFF_DISTANCE } from 'doc-mate-store/lib/constants/location';
import { GPSPositionData, LatLng } from 'doc-mate-store/lib/models/GPSPosition';
import { distanceBetween } from 'geofire-common';
import GoogleMapReact, { Coords } from 'google-map-react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import config from '../../config';
import { LoadStopWithFlag } from '../../containers/Track';
import { colors } from '../../themes';
import GenericMarker from './GenericMarker';
import TruckMarker from './TruckMarker';
import TruckPath from './TruckPath';

type Props = {
  className?: string;
  defaultCenter?: Coords;
  defaultZoom?: number;
  driverPath: GPSPositionData[];
  driverPosition?: GPSPositionData;
  stops?: LoadStopWithFlag[] | undefined;
  route?: LatLng[];
  showRoute?: boolean;
  selectedStop1: string;
  selectedStop2: string;
};

const DEFAULT_CENTER: Coords = { lat: 42.281389, lng: -83.748333 }; // Ann Harbor, MI
const DEFAULT_ZOOM = 11;

const RouteMap: React.FC<Props> = ({
  className,
  defaultCenter,
  defaultZoom,
  driverPath,
  driverPosition,
  stops,
  route,
  selectedStop1,
  selectedStop2,
  showRoute = false,
}) => {
  const [mapRef, setMapRef] = useState<any>();
  const [mapsRef, setMapsRef] = useState<any>();
  const classes = useStyles();
  const center = defaultCenter || DEFAULT_CENTER;
  const zoom = defaultZoom || DEFAULT_ZOOM;

  const [drawn, setDrawn] = useState(false);

  function onGoogleApiLoaded(map: any, maps: any) {
    setMapRef(map);
    setMapsRef(maps);
  }

  // function toLetters(num: number): string {
  //   var mod = num % 26,
  //     pow = (num / 26) | 0,
  //     out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
  //   return pow ? toLetters(pow) + out : out;
  // }

  useEffect(() => {
    if (mapRef && mapsRef) {
      const bounds = new mapsRef.LatLngBounds();
      let waypoints: { location: any; stopover: boolean }[] = [];

      if (route) {
        route.forEach(coords => {
          const { latitude, longitude } = coords;
          const latLng = new mapsRef.LatLng(latitude, longitude);
          bounds.extend(latLng);
          waypoints.push({
            location: latLng,
            stopover: false,
          });
        });
      }

      let directionsRenderer: any;
      if (waypoints.length > 1) {
        const origin = waypoints[0].location;
        const destination = waypoints[waypoints.length - 1].location;
        waypoints = waypoints.slice(1, waypoints.length - 1);

        const request = {
          origin,
          waypoints,
          destination,
          travelMode: mapsRef.TravelMode.DRIVING,
        };

        const directionsRendererPolyline = {
          strokeColor: colors.primary[300],
          strokeOpacity: 0.8,
          strokeWeight: 8,
        };
        const directionsService = new mapsRef.DirectionsService();
        directionsRenderer = new mapsRef.DirectionsRenderer({
          polylineOptions: directionsRendererPolyline,
          suppressMarkers: true,
          preserveViewport: true,
        });
        directionsRenderer.setMap(mapRef);
        if (showRoute) {
          directionsService.route(
            request,
            (result: unknown, status: string) => {
              if (status === 'OK') {
                directionsRenderer.setDirections(result);
              }
            },
          );
        }
      }

      if (!drawn) {
        mapRef.fitBounds(bounds);
        setDrawn(true);
      }

      return () => {
        if (directionsRenderer) {
          directionsRenderer.setMap(null);
        }
      };
    }
  }, [mapRef, mapsRef, drawn, route, showRoute]);

  const truckPath = useMemo(() => {
    let samplePaths: any[] = [];
    driverPath
      .sort((a, b) => {
        if (a.loadId && b.loadId) {
          if (a.loadId === b.loadId) {
            return a.timestamp - b.timestamp;
          } else {
            return a.loadId - b.loadId;
          }
        }
        return 1;
      })
      .forEach((p, index) => {
        if (index === 0) {
          samplePaths.push([p]);
        } else {
          if (p.loadId !== driverPath[index - 1].loadId) {
            samplePaths.push([p]);
          } else {
            if (
              !p.firstGps &&
              distanceBetween(
                [
                  driverPath[index - 1].latitude,
                  driverPath[index - 1].longitude,
                ],
                [p.latitude, p.longitude],
              ) > CUT_OFF_DISTANCE
            ) {
              samplePaths.push([p]);
            } else {
              samplePaths[samplePaths.length - 1].push(p);
            }
          }
        }
      });

    return samplePaths;
  }, [driverPath]);

  const mapOptions = {
    mapTypeControl: true,
  };

  return (
    <div className={[classes.root, className ? className : ''].join(' ')}>
      <GoogleMapReact
        bootstrapURLKeys={config.googleMaps.bootstrapURLKeys}
        defaultCenter={center}
        defaultZoom={zoom}
        // layerTypes={['TrafficLayer']}
        options={mapOptions}
        onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      >
        {mapRef && driverPosition && (
          <TruckMarker
            key={driverPosition.timestamp}
            heading={driverPosition.heading}
            lat={driverPosition.latitude}
            lng={driverPosition.longitude}
            map={mapRef}
          />
        )}
        {mapRef &&
          mapsRef &&
          driverPath &&
          truckPath.map((path: any, index) => (
            <TruckPath
              key={`truckPath-${index}`}
              maps={mapsRef}
              map={mapRef}
              path={path}
            />
          ))}

        {mapRef &&
          mapsRef &&
          stops &&
          stops.map((stop, index) => (
            <GenericMarker
              key={`stop-${index}`}
              lat={stop.loadStop.latlng.latitude}
              lng={stop.loadStop.latlng.longitude}
              stopType={stop.loadStop.stopType}
              isPickupDropoff={stop.isPickupDropff}
              stopNumber={index + 1}
              isSelected={
                selectedStop1 === stop.loadStop.location.id ||
                selectedStop2 === stop.loadStop.location.id
              }
            />
          ))}
      </GoogleMapReact>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
}));

export default observer(RouteMap);
